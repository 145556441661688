import useDataSource from "@/base/hooks/useDataSource";
import HorizontalCard from "@/components/elements/HorizontalCard";
import Pagination from "@/components/elements/Pagination";

import { correctAlgoliaSlug } from "@/base/helpers/correctAlgoliaSlug";
import * as styles from "./styles.module.scss";

interface SearchResultsProps {
  currentPage: number;
  totalPages?: number;
  setPage: (page: number) => void;
  defaultOgImage: string;
  searchResults: HitPageType[];
}

export function SearchResults({
  searchResults,
  setPage,
  totalPages,
  currentPage,
  defaultOgImage,
}: SearchResultsProps) {
  const { getNameByValue } = useDataSource<CategoryType>("categories");

  const renderResults = () =>
    searchResults.length > 0 ? (
      searchResults.map((result) => {
        switch (result?.component) {
          case "template_scientificUpdate":
            return (
              <HorizontalCard
                key={result.id}
                publishedDate={result.su_date_of_publication}
                title={result.su_title}
                excerpt={result.su_excerpt}
                labels={result.su_labels}
                heroImage={result.su_hero_img || defaultOgImage}
                categories={result.su_categories}
                url={correctAlgoliaSlug(result?.slug)}
                getNameByValue={getNameByValue}
              />
            );
          case "template_grant":
            return (
              <HorizontalCard
                key={result.id}
                publishedDate={result.gr_date}
                title={result.gr_title}
                excerpt={result.gr_description}
                labels={result.gr_labels}
                heroImage={result.gr_card_image || defaultOgImage}
                categories={result.gr_categories}
                url={result?.slug}
                getNameByValue={getNameByValue}
              />
            );
          case "template_event":
            return (
              <HorizontalCard
                key={result.id}
                publishedDate={result.event_date}
                title={result.event_title}
                excerpt={result.event_description}
                labels={result.event_labels}
                heroImage={result.event_thumbnail || defaultOgImage}
                categories={result.event_categories}
                url={result?.slug}
                getNameByValue={getNameByValue}
              />
            );
          case "template_scientificAuthor":
            return (
              <HorizontalCard
                key={result.id}
                title={result.sa_name}
                excerpt={result.sa_description}
                heroImage={result.sa_author_image || defaultOgImage}
                url={result?.slug}
                getNameByValue={getNameByValue}
              />
            );
          case "template_page":
            return (
              <HorizontalCard
                key={result.id}
                title={result.seo.title}
                excerpt={result.seo.description}
                url={result.slug.replace("/pages", "")}
                heroImage={result.seo.og_image || defaultOgImage}
              />
            );
          default:
            break;
        }
      })
    ) : (
      <p className={styles.noResults}>No results.</p>
    );

  return (
    <section className={styles.section}>
      <div className="container">
        {renderResults()}
        <Pagination
          setPage={setPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </div>
    </section>
  );
}
