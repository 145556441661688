import { lazy, useEffect, useState } from "react";

import { graphql, useStaticQuery } from "gatsby";

import { useInView } from "react-intersection-observer";
import MainLayout from "@/components/layout/Main";

import { useAlgoliaIndex } from "@/base/hooks/useAlgoliaIndex";
import useSessionStorage from "@/base/hooks/useSessionStorage";
import LazyIntersectionObserver from "@/components/elements/LazyIntersectionObserver";
import SearchHero from "@/components/sections/SearchHero";
import SearchResults from "@/components/sections/SearchResults";
import { useLocation } from "@reach/router";

const SearchPage = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const [storedValue, setValue] = useSessionStorage<string>(
    "alpro_searchPage",
    "0"
  );

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<
    [Array<HitPageType>, number]
  >([[], 0]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const { index, isIndexReady } = useAlgoliaIndex();

  useEffect(() => {
    if (page !== parseInt(storedValue)) {
      setPage(parseInt(storedValue));
    }
  }, []);

  useEffect(() => {
    const searchQueryParam = urlParams.get("query");
    if (index && isIndexReady) {
      index
        .search(searchQueryParam || "", {
          page,
          hitsPerPage: 5,
        })
        .then((res) => {
          if (res.page !== page) {
            setPage(res.page);
          }
          setTotalPages(res.nbPages);
          setSearchResults([res.hits as unknown as HitPageType[], res.nbHits]);
        })
        // eslint-disable-next-line no-console
        .catch(console.log);
    }
  }, [location.search, page]);

  const handleSetPage = (page: number) => {
    setValue(page.toString());
    setPage(page);
  };

  const { header, footer, poll, site } = useStaticQuery(graphql`
    query SearchPageQuery {
      site {
        siteMetadata {
          ogImage
        }
      }
      header: storyblokEntry(field_component: { eq: "template_header" }) {
        content
      }
      footer: storyblokEntry(field_component: { eq: "template_footer" }) {
        content
      }
      poll: storyblokEntry(field_component: { eq: "template_poll" }) {
        content
      }
    }
  `);

const parsedHeader = JSON.parse(header.content) as HeaderTemplate;
const parsedFooter = JSON.parse(footer.content) as FooterTemplate;
const { formId } = JSON.parse(poll.content) as PollTemplate;

  const LazyNewsletter = lazy(() => import("@/components/sections/Newsletter"));

  const seo = {
    description: "Search results",
    title: "Search results",
  };

  return (
    <MainLayout
      header={parsedHeader}
      footer={parsedFooter}
      formId={formId}
      seo={seo}
      type="website"
    >
      <SearchHero
        setSearchQuery={setSearchQuery}
        searchQuery={urlParams.get("query")}
        searchValue={searchQuery}
        searchResultsLength={searchResults[1]}
        setPage={handleSetPage}
      />
      <SearchResults
        searchResults={searchResults[0]}
        setPage={handleSetPage}
        currentPage={page}
        totalPages={totalPages}
        defaultOgImage={site.siteMetadata.ogImage as string}
      />

      <LazyIntersectionObserver
        Component={LazyNewsletter}
        inView={inView}
        ref={ref}
      />
    </MainLayout>
  );
};

export default SearchPage;
