// eslint-disable-next-line import/order
import { navigate } from "gatsby";

import Heading from "@/components/elements/Heading";
import Input from "@/components/elements/Input";
import beans from "@/assets/images/beans-bg.png";

import * as styles from "./styles.module.scss";

interface SearchHeroProps {
  searchQuery: string | null;
  searchValue: string;
  setSearchQuery: (searchValue: string) => void;
  searchResultsLength: number;
  setPage: (page: number) => void;
}

export function SearchHero({
  setSearchQuery,
  searchQuery,
  searchValue,
  searchResultsLength,
  setPage,
}: SearchHeroProps) {
  const handleSearch = () => navigate(`/search?query=${encodeURIComponent(searchValue)}`);

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(0);
    setSearchQuery("");
    handleSearch();
  };

  return (
    <section className={styles.section}>
      <img src={beans} alt="beans" className={styles.beans} />
      <div className="container">
        <span className={styles.subtitle}>search results</span>
        <Heading>Results for: &quot;{searchQuery}&quot;</Heading>
        <form onSubmit={handleSubmit}>
          <Input
            withIcon={
              <img
                src="/svgs/search.svg"
                alt="search"
                data-svg="true"
                className={styles.searchIcon}
                onClick={handleSearch}
              />
            }
            value={searchValue}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search again..."
            type="text"
            name="search"
          />
        </form>
        <p>
          We have found {searchResultsLength} {searchResultsLength === 1 ? "result" : "results"}
        </p>
      </div>
    </section>
  );
}
